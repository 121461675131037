<template>
  <span class="invoice-status" :class="statusClass">
    {{status}}
  </span>
</template>

<script>
  export default {
    name: "InvoiceStatus",
    props: {
      status: {
        type: String,
        required: true
      },
    },
    computed: {
      statusClass() {
        return {
          'invoice-status-paid': this.status === 'paid',
          'invoice-status-overdue': this.status === 'overdue'
        };
      }
    },
  }
</script>

<style lang="scss">
  .invoice-status {
    font-size: 14px;
    color: #363A39;
    background: #eeeeee;
    text-transform: capitalize;
    border-radius: 4px;
    padding: 2px 12px;

    &-paid {
      background: #E5F4EF;
    }

    &-overdue {
      color: #FFFFFF;
      background: #FF5C00;
    }
  }
</style>
