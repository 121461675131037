<template functional>
  <svg width="24px" height="24px" viewBox="0 0 24 24">
    <title>Download</title>
    <path d="M12.5,5 C15.3780733,5 17.7398255,7.21063379 17.9799067,10.0265517 C20.239388,10.2646675 22,12.1767273 22,14.5 C22,16.9852814 19.9852814,19 17.5,19 L7,19 C4.23857625,19 2,16.7614237 2,14 C2,11.2385763 4.23857625,9 7,9 L7.2056632,9.00484982 C7.85698945,6.69386464 9.98068308,5 12.5,5 Z M13,10 L11,10 L11,14 L9,14 L12,17 L15,14 L13,14 L13,10 Z"></path>
  </svg>
</template>

<script>
  export default {
    name: "DownloadIcon"
  }
</script>

<style scoped>

</style>
