<template>
  <div class="invoices">
    <BillingModuleLoader v-if="isLoading"></BillingModuleLoader>
    <table v-else-if="invoices.length > 0 || upcomingInvoice">
      <thead>
        <tr>
          <th>Status</th>
          <th>Date</th>
          <th>Amount</th>
          <th>Receipt</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="upcomingInvoice" class="current-usage">
          <td>
            <InvoiceStatus status="upcoming"/>
          </td>
          <td>{{upcomingInvoice.date|formatDate}}</td>
          <td>{{upcomingInvoice.amountDue|formatCurrency(upcomingInvoice.currency)}}</td>
          <td></td>
        </tr>
        <tr v-for="invoice in invoices" :key="invoice.id">
          <td>
            <InvoiceStatus :status="invoice.status"/>
          </td>
          <td>{{invoice.date|formatDate}}</td>
          <td>{{invoice.amountDue|formatCurrency(invoice.currency)}}</td>
          <td>
            <a :href="invoice.pdfUrl" target="_blank" class="download-button">
              <DownloadIcon/>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <span v-else class="fineprint">No invoices yet.</span>
  </div>
</template>

<script>
  import {DateTime} from "luxon";
  import DownloadIcon from "@/assets/DownloadIcon";
  import InvoiceStatus from "./InvoiceStatus";
  import BillingModuleLoader from "../BillingModuleLoader";
  import {GET_INVOICES} from "@/screens/settings/components/invoices/queries";

  export default {
    name: "Invoices",
    components: {BillingModuleLoader, InvoiceStatus, DownloadIcon},
    data() {
      return {
        invoices: [],
        upcomingInvoice: null
      }
    },
    apollo: {
      invoices: GET_INVOICES,
      upcomingInvoice: GET_INVOICES
    },
    computed: {
      isLoading() {
        return (
          this.invoices.length === 0 &&
          this.$apollo.queries.invoices.loading
        );
      }
    },
    filters: {
      formatCurrency: function (value, currency) {
        const formatter = Intl.NumberFormat('en-US',  {
          style: 'currency',
          currency: currency,
          minimumFractionDigits: 2
        });

        return formatter.format(value);
      },
      formatDate: function (value) {
        const date = DateTime.fromISO(value);
        return date.toFormat("LLLL d, yyyy");
      }
    }
  }
</script>

<style scoped lang="scss">
  .invoices table {
    width: 100%;
    border-collapse: collapse;

    thead {
      background: #F7F6F9;
    }

    tbody td {
      border-bottom: 1px solid rgba(#000, 0.15);
    }

    th {
      color: rgba(#000, 0.6);
      padding: 16px 8px;
      text-align: center;

      &:first-child {
        border-radius: 8px 0 0 8px;
      }

      &:last-child {
        border-radius: 0 8px 8px 0;
      }
    }

    td {
      padding: 16px 8px;
      text-align: center;
    }

    .download-button {
      display: inline-block;
      line-height: 0;
      border-radius: 4px;
      padding: 4px;
      appearance: none;
      cursor: pointer;
      background: none;
      fill: #009868;

      &:hover {
        background: rgba(#000, 0.05);
      }

      &:active {
        background: rgba(#000, 0.10);
      }
    }
  }
</style>
