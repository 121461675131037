import gql from "graphql-tag";

export const GET_INVOICES = gql`
  query GetInvoices {
    upcomingInvoice {
      id
      status
      currency
      amountDue
      date
    }

    invoices {
      id
      status
      paid
      currency
      amountDue
      pdfUrl
      date
    }
  }
`;
